import * as React from "react"
import DefaultLayout from "../components/DefaultLayout"
import styled from "styled-components"

const ErrorCode = styled.h1`
    font-size: 72pt;
    text-align: center;
    padding: 50px 0 0 0;
`

const NotFoundPage = (): JSX.Element => (
    <DefaultLayout>
        <ErrorCode>404: Not found</ErrorCode>
    </DefaultLayout>
)

export default NotFoundPage
